import { FC, useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'

import grass from '../../Assets/Footer/Elements/grass.svg'
import house from '../../Assets/Footer/Elements/house.svg'
import pumpkins2 from '../../Assets/Footer/Elements/pumpkins2.svg'
import boy from '../../Assets/Sprites/boy_css_sprites.png'
import girl from '../../Assets/Sprites/girl_css_sprites.png'
import witch from '../../Assets/Sprites/witch_css_sprites.png'
import zombie from '../../Assets/Sprites/zombie_css_sprites.png'

import { findPage } from '../../Helpers/PageHelpers'
import { useWindowSize } from '../../Hooks/MyHooks'
import Heikoop from './Heikoop'
import MyLamp2 from './MyLamp2'

export const AnimatedFooter: FC = () => {
  //  const element = useRef() as any;
  const bottomContainer = useRef<HTMLDivElement>(null)

  const animateFooterRef = useRef<HTMLDivElement>(null)
  const animate2FooterRef = useRef<HTMLDivElement>(null)
  const animate3FooterRef = useRef<HTMLDivElement>(null)
  const animate4FooterRef = useRef<HTMLDivElement>(null)

  const [lightsOn, setLightsOn] = useState(true)

  const location = useLocation()
  const windowSize = useWindowSize()

  useEffect(() => {
    const page = findPage(location.pathname)

    if (
      page !== undefined &&
      bottomContainer.current &&
      animateFooterRef.current &&
      animate2FooterRef.current &&
      animate3FooterRef.current &&
      animate4FooterRef.current
    ) {
      const scrollWidth = windowSize.width
      const scroll = page.Index * scrollWidth * -1

      animateFooterRef.current.style.width = `${windowSize.width}px`
      animate2FooterRef.current.style.width = `${windowSize.width}px`
      animate3FooterRef.current.style.width = `${windowSize.width}px`
      animate4FooterRef.current.style.width = `${windowSize.width}px`

      //4 animated footers
      bottomContainer.current.style.width = `${windowSize.width * 4}px`

      bottomContainer.current.style.transform = `translate(${scroll}px)`
      document.documentElement.style.setProperty('--glow-color', page.GlowColor)
    }
    //animate
  }, [location, windowSize])

  const brightness = lightsOn ? 100 : 0
  return (
    <div className="bottom shadow children-footer">
      {/* <MyLamp lightsOn={lightsOn} onClick={() => setLightsOn(!lightsOn)} /> */}

      <MyLamp2 lightsOn={lightsOn} onClick={() => setLightsOn(!lightsOn)} />
      <div className="bottomContainer" ref={bottomContainer}>
        {/* first */}

        <div className="animateFooter footer1" ref={animateFooterRef}>
          <div className="sign-container">
            <div className="sign">
              <div className="border-w">Piersgil</div>
            </div>
            <div className="post"></div>
          </div>
          {/* <img src={pumpkins} className="pumpkins" /> */}
          <img src={grass} className="bg" />
        </div>
        {/* second */}
        <div className="animateFooter footer1" ref={animate2FooterRef}>
          <img src={pumpkins2} className="pumpkins" />
          <img src={grass} className="bg" />
        </div>
        {/* third */}
        <div className="animateFooter" ref={animate3FooterRef}>
          <img src={house} className="house" />
          <img src={grass} className="bg" />
        </div>
        <div className="animateFooter footer1" ref={animate4FooterRef}>
          <Heikoop />
          <img src={grass} className="bg" />
        </div>
        <div>
          <div
            className="boy animate_boy2"
            style={{
              backgroundImage: `url(${boy})`,
              filter: `brightness(${brightness}%)`
            }}
          ></div>
          <div
            className="girl animate_girl"
            style={{
              backgroundImage: `url(${girl})`,
              filter: `brightness(${brightness}%)`
            }}
          ></div>
          <div
            className="witch animate_witch"
            style={{
              backgroundImage: `url(${witch})`,
              filter: `brightness(${brightness}%)`
            }}
          ></div>
          <div
            className="zombie animate_zombie"
            style={{
              backgroundImage: `url(${zombie})`,
              filter: `brightness(${brightness}%)`
            }}
          ></div>
        </div>
      </div>
    </div>
  )
}
