import React, { useEffect, useRef } from 'react'

import './ghost.css'

const GhostLoader: React.FC = () => {
  const phraseOutputRef = useRef<HTMLParagraphElement>(null)
  useEffect(() => {
    if (phraseOutputRef.current !== null) {
      const phrases = [
        'pompoenen snijden',
        'versieringen ophangen',
        'snoepzakken vullen',
        'outfit voorbereiden',
        'spookmeter afstemmen',
        'contact opnemen met de geesten'
      ]
      const phraseDelay = 1700,
        displayPhrases = () => {
          let delay = 0
          phrases.forEach((phrase) => {
            setTimeout(() => {
              if (phraseOutputRef.current !== null) {
                phraseOutputRef.current.textContent = phrase
              }
            }, delay)
            delay += phraseDelay
          })
        }

      displayPhrases()

      const intervalId = setInterval(
        displayPhrases,
        phrases.length * phraseDelay
      )

      return () => clearInterval(intervalId) // Cleanup interval on component unmount
    }
  }, [phraseOutputRef])

  return (
    <div className="ghost-container">
      <div className="ghost">
        <div className="ghost-body">
          <div className="ghost-mouth" />
        </div>
        <div className="ghost-tail ghost-tail-1" />
        <div className="ghost-tail ghost-tail-2" />
        <div className="ghost-tail ghost-tail-3" />
        <div className="ghost-tail ghost-tail-4" />
      </div>
      <p className="phrase-output" ref={phraseOutputRef}>
        Pompoenen snijden
      </p>
    </div>
  )
}

export default GhostLoader
