import React, { useEffect, PropsWithChildren, useRef, ReactNode } from 'react'
import GhostLoader from './GhostLoader/GhostLoader'

interface IProps extends PropsWithChildren {
  isLoading?: boolean
  renderChildren: () => ReactNode
}

//provides a fade in effect
const BaseScreen: React.FC<IProps> = ({
  renderChildren,
  isLoading = false
}) => {
  const element = useRef<HTMLDivElement>(null)
  useEffect(() => {
    element?.current?.classList.remove('active')
    //fades in content
    setTimeout(() => {
      element?.current?.classList.add('active')
    }, 500)
  }, [null])

  const renderChildrenOrLoader = () => {
    if (isLoading) {
      return <GhostLoader />

      return (
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      )
    }

    return <>{renderChildren()}</>
  }

  return (
    <div className="content" ref={element}>
      {renderChildrenOrLoader()}
    </div>
  )
}

export default BaseScreen
