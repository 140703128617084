import React from 'react'

import BaseScreen from '../../Components/BaseScreen'
import { useGetContentItemByPath } from '../../Hooks/UmbracoHooks'
import { IAanmelden } from '../../Models/Umbraco'

interface IProps {}

const Faq: React.FC<IProps> = (props) => {
  const { result: sponsors, isLoading } =
    useGetContentItemByPath<IAanmelden>('sponsors')

  return (
    <>
      <BaseScreen
        isLoading={isLoading}
        renderChildren={() => {
          return (
            <div
              className="container shadow p-3 mb-7 rounded "
              dangerouslySetInnerHTML={{
                __html: sponsors.properties.contentBlock.markup
              }}
            />
          )
        }}
      />
    </>
  )
}

export default Faq
