import React, { useEffect, useState } from 'react'

import witch from '../../Assets/Images/witch-img.png'
import wink from '../../Assets/Images/Icons/winking-face.png'
import smile from '../../Assets/Images/Icons/smile-beam.png'
import BaseScreen from '../../Components/BaseScreen'
import { Constants } from '../../Models'
import { useWindowSize } from '../../Hooks/MyHooks'

interface IProps {}

const Map: React.FC<IProps> = (props) => {
  const [mapHeight, setMapHeight] = useState<number>(400)
  const windowSize = useWindowSize()
  useEffect(() => {
    //

    const navBarHeight =
      document.getElementsByClassName('navbar')[0].clientHeight

    const footerBgHeight = document.getElementsByClassName('bg')[0].clientHeight

    setMapHeight(windowSize.height - navBarHeight - footerBgHeight / 2)

    //console.log('window', windowSize, navBar.clientHeight)
  }, [windowSize])

  return (
    <>
      <BaseScreen
        renderChildren={() => {
          return (
            <iframe
              src={Constants.MapEmbed}
              title="Kaart"
              className="map"
              style={{ height: mapHeight }}
            />
          )
        }}
      ></BaseScreen>
    </>
  )
}

export default Map
