import { ChatCompletion, ChatCompletionMessageParam } from 'openai/resources'

//const apiUrl = 'http://127.0.0.1:11434/v1/chat/completions'
const apiUrl = 'https://proxy.superdopey.nl/myapp/v1/chat/completions'
const apikey = 'ollama'
const headers = {
  'Content-Type': 'application/json',
  Authorization: `Bearer ${apikey}`
}
export const fetchChatCompletion = async (
  myMessages: ChatCompletionMessageParam[]
): Promise<ChatCompletion> => {
  //ollama run llama3.2:3b ""

  const data = {
    //model: 'llama3.1:8b',
    //model: 'llama3.2:3b',
    //model: 'gemma2:latest',
    //model: 'mistral-nemo',
    //model: 'qwen2.5:14b',

    //lm-studio
    model: 'Llama-3.2-3B-Instruct-GGUF',
    messages: myMessages
    //temperature: 0.7
  }

  const response = await fetch(apiUrl, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(data)
  })

  if (!response.ok) {
    //throw new Error('Forsooth, a scourge upon our fetch quest: ' + response.statusText);
    throw new Error('Network response was not ok' + response.statusText)
  }
  const jsonData: ChatCompletion = await response.json()
  return jsonData
}
