import React, { useEffect, useState } from 'react'

import BaseScreen from '../../Components/BaseScreen'
import ChatBot, { Flow, Params } from 'react-chatbotify'
import { OpenAI } from 'openai'
import { ChatCompletion, ChatCompletionMessageParam } from 'openai/resources'
import { FinalRequestOptions } from 'openai/core'
import { fetchChatCompletion } from '../../Helpers/ollamaHelper'

interface IProps {}

const Ghost: React.FC<IProps> = (props) => {
  //   const { result: sponsors, isLoading } =
  //     useGetContentItemByPath<IAanmelden>('/sponsors')

  const openai = new OpenAI({
    //baseURL: 'http://192.168.86.199:2001/v1',
    baseURL: 'http://127.0.0.1:11434/v1',
    //baseURL: 'https://proxy.superdopey.nl',
    apiKey: 'ollama', // required but unused,
    dangerouslyAllowBrowser: true
  })

  async function fetchData() {
    const chatCompletion = await fetchChatCompletion(myMessages)
    myMessages.push({
      role: 'assistant',
      content: chatCompletion.choices[0].message.content
    })

    //console.log('result', chatCompletion)

    return chatCompletion.choices[0].message.content
  }

  let myMessages: ChatCompletionMessageParam[] = []
  // const [myMessages, setMyMessages] = useState<
  //   Array<ChatCompletionMessageParam>
  // >([])
  function updateMessages(param: ChatCompletionMessageParam) {
    //setMyMessages([...myMessages, param])
    myMessages.push(param)
    return myMessages
    //return messageHistory
  }

  const flow: Flow = {
    start: {
      message: (params: Params) => {
        //params.
        updateMessages({
          role: 'user',
          content:
            'Jij bent de Geest van PiersGil.' +
            'Eerst stel je je voor als de geest van PiersGil, je bent 50 jaar geleden tijdens Halloween omgekomen en sindsdien kom je elk jaar terug om de bewoners van PiersGil de stuipen op het lijf te jagen' +
            'Vervolgens vertel je een kort eng verhaal van maximaal 100 woorden over de Halloween van vorig jaar.' +
            'Hierna vraag je aan mij hoe het verhaal verder moet gaan middels een multiple choice vraag.' +
            'A: <Optie 1 />' +
            'B: <Optie 2 />' +
            'C: <Optie 3 />' +
            'Ik geef antwoord en je verteld het verhaal verder. Dit doe je 3 keer' +
            // 'Geef vervolgens een serie van 3 multiple choice vragen aan mij waarmee ik kan bepalen hoe het verhaal verder gaat.' +
            // 'Deze vragen moet ik achter elkaar beantwoorden (Dus 1 multiple choice vraag en dan een antwoord van mij). ' +
            'Na de derde en laatste vraag maak je het verhaal af en vraag je of ik nog een verhaal wil horen van de Geest van PiersGil.'
        })
        params.injectMessage('Wat is je naam?')

        // updateMessages('assistant', 'Hi my name is Edubot. Im here to help you')
        // updateMessages(
        //   'assistant',
        //   'Can you write your username, so i can address you ?'
        // )
      },
      path: 'askname'
    },
    //path: "askname"
    askname: {
      message: async (params) => {
        updateMessages({ role: 'user', content: `${params.userInput}` })
        params.injectMessage(`Leuk je te ontmoeten ${params.userInput}!`)
        updateMessages({
          role: 'assistant',
          content: `Leuk je te ontmoeten ${params.userInput}!`
        })

        const result = await fetchData()

        if (result === null) {
          return
        }

        return result
      },
      path: 'loop'
    },
    loop: {
      message: async (params: Params) => {
        updateMessages({ role: 'user', content: `${params.userInput}` })
        const result = await fetchData()

        if (result === null) {
          return
        }

        return result
      },
      path: 'loop'
    }
  }
  /*
  const flow2:Flow={
		start: {

			message: "Enter your OpenAI api key and start asking away!",
			path: "loop",
			isSensitive: true
		},
		
		loop: {
			message: async (params) => {
				await call_openai(params);
			},
			path: () => {
				if (hasError) {
					return "start"
				}
				return "loop"
			}
		}
	}
*/
  useEffect(() => {
    //init
    //fetchData()
  }, [])

  // useEffect(() => {
  //   initGhostOllama()
  // }, [])

  //const text = result ?? ''

  return (
    <>
      <BaseScreen
        //isLoading={isLoading}
        renderChildren={() => {
          return (
            <ChatBot
              styles={{
                chatWindowStyle: {
                  width: '100%'
                }
              }}
              settings={{
                general: {
                  embedded: true,
                  showFooter: false,
                  primaryColor: '#ff5003',
                  secondaryColor: '#000'
                },

                chatHistory: { disabled: true },
                botBubble: {
                  simStream: true,
                  avatar:
                    'https://png.pngtree.com/png-clipart/20230502/original/pngtree-halloween-cartoon-character-avatar-png-image_9132198.png',
                  showAvatar: true
                },
                header: {
                  avatar:
                    'https://png.pngtree.com/png-clipart/20230502/original/pngtree-halloween-cartoon-character-avatar-png-image_9132198.png',
                  showAvatar: true,
                  title: 'De Geest van PiersGil!'
                },
                chatInput: {
                  enabledPlaceholderText: 'Je antwoord...'
                },
                fileAttachment: { disabled: true }
              }}
              flow={flow}
            />
          )
          // return (
          //   <div
          //     className="container shadow p-3 mb-7 rounded "
          //     dangerouslySetInnerHTML={{
          //       __html: text
          //     }}
          //   />
          // )
        }}
      />
    </>
  )
}

export default Ghost
