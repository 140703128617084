import React, { useRef } from 'react'
import { NavLink } from 'react-router-dom'
import { Pages } from '../Screens/NavLayout'
import { Constants } from '../Models'
interface IProps {}

const Header: React.FC<IProps> = (props) => {
  //const navigate = useNavigate()

  const navBarCollapseRef = useRef<HTMLDivElement>(null)

  return (
    <nav className="navbar navbar-expand-lg navbar-dark  p-3">
      <button
        className="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarsExample04"
        aria-controls="navbarsExample04"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon" />
      </button>
      <div
        className="collapse navbar-collapse"
        id="navbarsExample04"
        ref={navBarCollapseRef}
      >
        <ul className="navbar-nav me-auto mb-2 mb-md-0 ">
          <li className="nav-item my-2">
            <NavLink
              to={Pages.Home.Path}
              className="nav-link"
              end
              onClick={() => {
                navBarCollapseRef.current?.classList.remove('show')
              }}
            >
              Home
            </NavLink>
          </li>
          <li className="nav-item my-2">
            <NavLink
              to={Pages.Aanmelden.Path}
              className="nav-link"
              onClick={() => {
                navBarCollapseRef.current?.classList.remove('show')
              }}
            >
              Aanmelden
            </NavLink>
          </li>
          <li className="nav-item my-2">
            <NavLink
              to={Pages.Faq.Path}
              className="nav-link"
              onClick={() => {
                navBarCollapseRef.current?.classList.remove('show')
              }}
            >
              F.A.Q.
            </NavLink>
          </li>
          <li className="nav-item my-2">
            <NavLink
              to={Pages.Kaart.Path}
              className="nav-link"
              onClick={() => {
                navBarCollapseRef.current?.classList.remove('show')
              }}
            >
              Kaart
            </NavLink>
          </li>
          <li className="nav-item my-2">
            <a
              href="https://www.facebook.com/groups/halloweenpiershil/"
              className="nav-link"
            >
              Facebook
            </a>
          </li>
          <li className="nav-item my-2">
            <NavLink
              to={Pages.Sponsors.Path}
              className="nav-link"
              onClick={() => {
                navBarCollapseRef.current?.classList.remove('show')
              }}
            >
              Sponsors
            </NavLink>
          </li>
        </ul>
      </div>
    </nav>
  )
}

export default Header
