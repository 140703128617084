import React, { useEffect, useState } from 'react'

import witch from '../../Assets/Images/witch-img.png'
import BaseScreen from '../../Components/BaseScreen'
import { ReplaceUmbracoConstants } from '../../Helpers/UmbracoHelpers'
import {
  useGetContentItemByPath,
  useGetContentQuery
} from '../../Hooks/UmbracoHooks'
import { IRoot } from '../../Models/Umbraco'
import { IFaqQueryResult } from '../../Models/Umbraco/IFaqQueryResult'
import { IFaqQuestionsResult } from '../../Models/Umbraco/IFaqQuestionsResult'

interface IProps {}

const Faq: React.FC<IProps> = (props) => {
  const { result: root, isLoading: rootIsLoading } =
    useGetContentItemByPath<IRoot>('/')
  const { result: faqCategories, isLoading } =
    useGetContentQuery<IFaqQueryResult>('fetch=children:faq')

  const [activeTab, setActiveTab] = useState<string | undefined>(undefined)

  const { result: questions } = useGetContentQuery<IFaqQuestionsResult>(
    `fetch=children:${activeTab}`
  )

  useEffect(() => {
    //set inital first tab selected
    if (faqCategories !== undefined) {
      setActiveTab(faqCategories.items[0].route.path)
    }
  }, [faqCategories])

  const renderTabs = () => {
    const tabs = faqCategories.items.map((cat, key) => {
      //todo: active tab
      const activeClassName = activeTab === cat.route.path ? 'active' : ''
      return (
        <li className="nav-item" role="presentation" key={key}>
          <button
            className={`nav-link ${activeClassName}`}
            type="button"
            role="tab"
            onClick={() => {
              setActiveTab(cat.route.path)
            }}
          >
            {cat.name}
          </button>
        </li>
      )
    })

    return (
      <ul className="nav nav-tabs" id="myTab" role="tablist">
        {tabs}
      </ul>
    )
  }

  const renderTabContent = () => {
    //
    if (questions === undefined) {
      return null
    }

    const questionsElements = questions.items.map((question, key) => (
      <li key={key}>
        <b>{question.name}</b>
        <div
          dangerouslySetInnerHTML={{
            __html: ReplaceUmbracoConstants(
              question.properties.answer.markup,
              root
            )
          }}
        />
      </li>
    ))

    return (
      <div className="tab-content tab-pane">
        <ul className="faq">
          {questionsElements}
          <li>
            <p>
              PS: Wij vinden Halloween geweldig maar dat vindt natuurlijk niet
              iedereen, om welke reden dan ook. <br />
              Respecteer elkaar in deze keuze en laat iedereen in zijn of haar
              waarde! Dat hopen wij andersom namelijk ook.{' '}
              <img
                className="icon"
                src="/content/img/icons/winking-face.png"
                alt="wink"
              />
            </p>
          </li>
        </ul>
      </div>
    )
  }

  return (
    <>
      <BaseScreen
        isLoading={rootIsLoading || isLoading}
        renderChildren={() => {
          return (
            <div className="container shadow p-3 mb-5 rounded text-center">
              <div className="">
                <img
                  className="witch-img d-none d-lg-block"
                  src={witch}
                  alt="witch"
                />
                <h2>Veelgestelde vragen</h2>
                {renderTabs()}
                {renderTabContent()}
              </div>
            </div>
          )
        }}
      />
    </>
  )
}

export default Faq
