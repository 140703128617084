import React from 'react'
import BaseScreen from '../../Components/BaseScreen'
import { ReplaceUmbracoConstants } from '../../Helpers/UmbracoHelpers'
import {
  useGetContentItemByPath,
  useSetInternalLinks
} from '../../Hooks/UmbracoHooks'
import { IAanmelden, IRoot } from '../../Models/Umbraco'
interface IProps {}

const About: React.FC<IProps> = (props) => {
  const { result: root, isLoading: rootIsLoading } =
    useGetContentItemByPath<IRoot>('/')
  const { result: aanmelden, isLoading } =
    useGetContentItemByPath<IAanmelden>('aanmelden')

  //make link use react-router instead of direct browse
  useSetInternalLinks([aanmelden])

  if (rootIsLoading || isLoading) {
    return null
  }

  return (
    <BaseScreen
      isLoading={rootIsLoading || isLoading}
      renderChildren={() => {
        return (
          <div
            className="container shadow p-3 mb-5 rounded "
            dangerouslySetInnerHTML={{
              __html: ReplaceUmbracoConstants(
                aanmelden.properties.contentBlock.markup,
                root
              )
            }}
          />
        )
      }}
    />
  )
}

export default About
